exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-member-login-js": () => import("./../../../src/pages/member-login.js" /* webpackChunkName: "component---src-pages-member-login-js" */),
  "component---src-pages-member-profile-js": () => import("./../../../src/pages/member-profile.js" /* webpackChunkName: "component---src-pages-member-profile-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsquake-js": () => import("./../../../src/pages/newsquake.js" /* webpackChunkName: "component---src-pages-newsquake-js" */),
  "component---src-pages-vana-js": () => import("./../../../src/pages/vana.js" /* webpackChunkName: "component---src-pages-vana-js" */),
  "component---src-templates-benefits-js": () => import("./../../../src/templates/benefits.js" /* webpackChunkName: "component---src-templates-benefits-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-details-js": () => import("./../../../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */),
  "component---src-templates-event-details-js": () => import("./../../../src/templates/eventDetails.js" /* webpackChunkName: "component---src-templates-event-details-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-member-profile-js": () => import("./../../../src/templates/memberProfile.js" /* webpackChunkName: "component---src-templates-member-profile-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-partner-details-js": () => import("./../../../src/templates/partnerDetails.js" /* webpackChunkName: "component---src-templates-partner-details-js" */)
}

